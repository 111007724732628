@use '@angular/material' as mat;
@use '@angular/material-experimental' as matx;

$theme: mat.define-theme();

@include mat.core();

:root {
  @include mat.all-component-themes($theme);
}
