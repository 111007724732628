/* You can add global styles to this file, and also import other style files */

@use '@porsche-design-system/components-angular/styles' as *;
@import "bootstrap-4-grid/css/grid.min.css";
@import "bootstrap-4-grid/scss/grid/functions.scss";
@import "bootstrap-4-grid/scss/grid/variables.scss";
@import 'normalize.css';

.heading {
  @include pds-heading-medium;
  color: $pds-theme-light-primary;
  margin: 0;
}

// Heading
.heading-large {
  @include pds-heading-large;
}

.heading-small {
  @include pds-heading-small;
}

// Text
.text-medium {
  @include pds-text-medium;
}
