@mixin pds-text-large {
  font:
    normal normal 400 clamp(1.27rem, 0.51vw + 1.16rem, 1.78rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin pds-text-medium {
  font:
    normal normal 400 clamp(1.13rem, 0.21vw + 1.08rem, 1.33rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin pds-text-small {
  font:
    normal normal 400 1rem/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin pds-text-x-large {
  font:
    normal normal 400 clamp(1.42rem, 0.94vw + 1.23rem, 2.37rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin pds-text-x-small {
  font:
    normal normal 400 clamp(0.81rem, 0.23vw + 0.77rem, 0.88rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}

@mixin pds-text-xx-small {
  font:
    normal normal 400 0.75rem/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
  hyphens: auto;
  overflow-wrap: break-word;
}
