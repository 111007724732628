$pds-theme-light-primary: #010205;
$pds-theme-light-background-base: #fff;
$pds-theme-light-background-surface: #eeeff2;
$pds-theme-light-background-shading: rgba(1, 2, 5, 0.67);
$pds-theme-light-background-frosted: hsl(240 4% 85%/35%);
$pds-theme-light-contrast-low: #d8d8db;
$pds-theme-light-contrast-medium: #6b6d70;
$pds-theme-light-contrast-high: #535457;
$pds-theme-light-notification-success: #197e10;
$pds-theme-light-notification-success-soft: #e4ffec;
$pds-theme-light-notification-warning: #f3be00;
$pds-theme-light-notification-warning-soft: #fff4d2;
$pds-theme-light-notification-error: #cc1922;
$pds-theme-light-notification-error-soft: #ffe2e4;
$pds-theme-light-notification-info: #2762ec;
$pds-theme-light-notification-info-soft: #d3e1ff;
$pds-theme-light-state-hover: rgba(148, 149, 152, 0.18);
$pds-theme-light-state-active: rgba(148, 149, 152, 0.2);
$pds-theme-light-state-focus: #1a44ea;
$pds-theme-light-state-disabled: #949598;
$pds-theme-dark-primary: #fbfcff;
$pds-theme-dark-background-base: #0e0e12;
$pds-theme-dark-background-surface: #212225;
$pds-theme-dark-background-shading: rgba(38, 38, 41, 0.67);
$pds-theme-dark-background-frosted: hsl(240 3% 26%/35%);
$pds-theme-dark-contrast-low: #404044;
$pds-theme-dark-contrast-medium: #88898c;
$pds-theme-dark-contrast-high: #afb0b3;
$pds-theme-dark-notification-success: #09d087;
$pds-theme-dark-notification-success-soft: #003320;
$pds-theme-dark-notification-warning: #f7cb47;
$pds-theme-dark-notification-warning-soft: #362b0a;
$pds-theme-dark-notification-error: #fc4040;
$pds-theme-dark-notification-error-soft: #3a0f0f;
$pds-theme-dark-notification-info: #178bff;
$pds-theme-dark-notification-info-soft: #04294e;
$pds-theme-dark-state-hover: rgba(148, 149, 152, 0.18);
$pds-theme-dark-state-active: rgba(126, 127, 130, 0.2);
$pds-theme-dark-state-focus: #1a44ea;
$pds-theme-dark-state-disabled: #7e7f82;
