@mixin pds-heading-large {
  font:
    normal normal 600 clamp(1.27rem, 0.51vw + 1.16rem, 1.78rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
}

@mixin pds-heading-medium {
  font:
    normal normal 600 clamp(1.13rem, 0.21vw + 1.08rem, 1.33rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
}

@mixin pds-heading-small {
  font:
    normal normal 600 1rem/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
}

@mixin pds-heading-x-large {
  font:
    normal normal 600 clamp(1.42rem, 0.94vw + 1.23rem, 2.37rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
}

@mixin pds-heading-xx-large {
  font:
    normal normal 600 clamp(1.6rem, 1.56vw + 1.29rem, 3.16rem)/calc(6px + 2.125ex) "Porsche Next",
    "Arial Narrow",
    Arial,
    "Heiti SC",
    SimHei,
    sans-serif;
}
